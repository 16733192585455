h2 {
  font-family: $font-family-sans-condensed;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 40px;
  }
}

h3 {
  font-family: $font-family-sans-condensed;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
    line-height: 35px;
  }
}

.contentCore {
  @include contentCore;

  b, strong {
    font-weight: 600;
  }

  img {
    @include img-fluid;
  }

  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

// Data Tables
table.listing,
table.plain,
table.underlined {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 0 0.75em;
  width: 100%;
}
table.underlined td,
table.underlined th,
table.plain td,
table.plain th,
table.listing td,
table.listing th {
  padding: 0.5em 1em;
  vertical-align: top;
}
table.plain th,
table.plain td {
  border: 1px solid #dadacc;
}
table.listing td.listingCheckbox,
table.listing th.listingCheckbox {
  text-align: center;
}
table.listing {
  // border-left:1px solid #ddd;
  // border-bottom:1px solid #ddd;
}
table.listing .top {
  // border-left:1px solid #ddd;
  // border-top:1px solid #ddd;
  // border-right:1px solid #ddd;
}
table.listing th {
  background-color:#dadacc;
  border: 1px solid #cacbbe;
  font-weight: 700;
  // border: 0;
  // border-style:solid solid none;
  // color:#666;
  // text-align: left;
}
table.listing tr.odd th {
  // border:0.1em solid #fff;
  // border-style:solid solid none none;
}
table.listing tbody tr {
  // text-align: left;
}
table.listing tbody tr.odd {
  // background-color:#f3f3e4;
}
table.listing tbody tr td {
  // background: transparent;
}
table.listing tbody tr:nth-child(odd) td {
  background-color:#f3f3e4;
}
table.listing td {
  // border-right:1px solid #ddd;
  border: 1px solid #cacbbe;
}
table.listing a {
  border: 0 !important;
  // display: inline-block;

  &:hover {
    padding-bottom: 0 !important;
  }
}
table.listing td.draggable {
  background-color: #E1E3D4 !important;
  // border-bottom: 1px solid #fff;
  padding: 4px;
  // text-shadow:#fff 1px 1px 1px;
  text-align: center;
}

// underlined
table.underlined td,
table.underlined th {
  border-top: 3px solid #e1e3d4;
  border-bottom: 3px solid #e1e3d4;

  p {
    margin-top: 20px;
  }
}

/* Vertical table data listings */
table.vertical {
  // border: 1px solid #f3f3e4;
}

table.vertical th {
  text-align: right;
}
table.vertical td {
  background-color: transparent !important;
  border: 1px solid #cacbbe;
  // border-top: 0.1em solid #e7e7e7;
  padding: 0.5em;
}

/* Grid table listings */
table.grid td,
table.grid th {
  border:1px solid #e7e7e7;
  padding:0.5em;
  }
.dragindicator {
  outline:2px solid #ffd700;
  }
#foldercontents-order-column {
  padding:0;
}

.visualHighlight {
  background-color: #ffb;
}

.pullquote {
  font-family: $font-family-sans;
  font-size: 20px;
  margin: 50px 40px;

  @include media-breakpoint-up(md) {
    font-size: 22px;
    margin: 50px 90px;
  }
}

.portaltype-easyform form#form {
  display: block;
}
