$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Sans Italic'),
    local('IBMPlexSans-Italic'),
    url('#{$font-prefix}/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Italic.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Italic.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';
@import 'greek';