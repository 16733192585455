.template-project_view .content {
  max-width: none;
  padding: 0;
}

.template-project_view .documentByLine {
  @include contentDimensions;
}

.template-project_view .content .portalMessage,
.template-project_view #edit-bar {
  max-width: 875px;
  margin-right: auto;
  margin-left: auto;
}
.projectView {
  &__content {
    @include contentDimensions;
  }
}

.projectViewInfo {
  background-color: #e1e3d4;
  margin-bottom: 50px;
  padding: 50px 20px;

  @include media-breakpoint-up(lg) {
    display: flex;
    margin-left: calc((100% - 875px) / 2 * 0.6);
    padding: 50px 20% 50px 75px;
  }

  &__col {
    @include media-breakpoint-up(lg) {
      flex: 1 0 33.33%;
      // max-width: 33.33%;
      padding: 0 15px;
    }
  }

  &__item {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    @include smallerFont;
    font-weight: 500;
    letter-spacing: $letterSpacing;
    margin-bottom: 12px;
  }
}
