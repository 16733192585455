.relatedItems {
  margin-bottom: 50px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
    max-width: 1088px;
  }
}

.relatedItem {
  background-color: #e1e3d4;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 250px;
  padding: 30px 30px 20px;
  @include hoverEffect;

  @include media-breakpoint-up(xl) {
    min-height: 330px;
  }

  &__tags {
    font-family: $font-family-sans-condensed;
    font-size: 14px;
    letter-spacing: $letterSpacing;
    line-height: 1.43;
    margin-bottom: 10px;
  }

  &__title {
    font-family: $font-family-sans;
    font-size: 25px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  &__more {
    @include readMore;
  }

  &:hover &__more {
    border-color: #000;
  }

  &__btn.disabled {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}
