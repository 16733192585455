.sectionStage {
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 70px;
    position: relative;

    &__controls {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
    }
  }
}

.stageSlider {
  background-color: #fff;

  @include media-breakpoint-up(lg) {
    padding-right: 11.11%;
    padding-bottom: 50px;
    padding-left: 11.11%;
    position: relative;
  }

  &__more {
    display: inline-block;
    font-family: $font-family-sans-condensed;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: $letterSpacing;
    margin-top: auto;
    text-transform: uppercase;
    @include contentLink(2px);

    &--hidden {
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.stageSliderContent {
  background-color: rgba(145, 205, 205, .95);
  font-family: $font-family-sans;
  display: flex;
  margin-top: -50px;
  margin-right: 20px;
  min-height: 250px;
  padding: 20px;
  position: relative;

  &__inner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 0;
    padding-right: 0;
    padding-left: 11.11%;
    width: 43.89%;
    position: absolute;
    bottom: 0;
    left: 0;

    &__inner {
      padding-right: 13.56%;
      padding-left: 13.56%;
    }
  }

  &__title {
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 35px;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 20px;
    line-height: 1.5;
  }
}

.stageSliderFigure {
  height: 250px;
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    height: 500px;
    margin-left: 0;

    &--slider {
      height: 550px;
    }
  }

  picture {
    display: block;
    height: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
