$mark-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 13.215 10.089'><path d='M2.094 5.905l3.074 2.216 5.926-6' fill='none' stroke='#91cdcd' stroke-linecap='round' stroke-width='3'/></svg>");

.form-group label,
.form-group .label {
  @include smallerFont;
  @include sansFontFamily;
  letter-spacing: $letterSpacing;
  vertical-align: top;
  margin-bottom: $label-margin-bottom;
}

div.field span.required:after {
  color: #f00;
  content: " ■";
}

.formHelp {
  color: #1c2523;
  display: block;
  font-family: $font-family-base;
  font-size: 17px;
  font-weight: normal;
  line-height: 1.77;

  &:not(:empty) {
    margin-bottom: $label-margin-bottom;
  }
}

.fieldErrorBox {
  color: $danger;

  &:not(:empty) {
    margin-bottom: $label-margin-bottom;
  }
}

.fieldErrorBox:not(:empty) + .form-control {
  box-shadow: 0 0 0 2px $danger;
}

.ArchetypesMultiSelectionWidget > label {
  display: block;
}

.checkboxWrapper {
  display: inline-block;
  margin-right: 14px;
  position: relative;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__visual {
    width: 100%;
    height: 100%;
    border: 3px solid #344643;
    transition: border-color .15s;
  }

  input:checked + &__visual {
    background: escape-svg($mark-icon) no-repeat 50% 50%;
    border-color: #91cdcd;
  }
}

.radioWrapper {
  display: inline-block;
  margin-right: 14px;
  position: relative;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__visual {
    width: 100%;
    height: 100%;
    border: 3px solid #344643;
    border-radius: 50%;
    transition: border-color .15s;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      background-color: #91cdcd;
      border-radius: 50%;
      opacity: 0;
    }
  }

  input:checked + &__visual {
    border-color: #91cdcd;

    &::after {
      opacity: 1;
      transition: opacity .15s;
    }
  }
}
