.sectionProjects {
  font-family: $font-family-sans;
  margin-bottom: 50px;
  padding: 0 20px;

  @include media-breakpoint-up(xl) {
    max-width: 1098px;
    margin-bottom: 100px;
  }

  &__all {
    margin-top: 30px;
    text-align: center;
  }

  &__allLink {
    @include smallerFont;
    font-weight: 500;
    letter-spacing: $letterSpacing;
    text-transform: uppercase;
    @include link;
  }
}

.projectCardList {
  @include media-breakpoint-up(lg) {
    position: relative;
    height: 400px;

    &__inner {
      position: absolute;
      top: 0;
      left: 50%;
      width: 400px;
      transform: rotate(-90deg) translateY(-50%);;
      transform-origin: 200px 200px;
    }
  }
}

.projectCard {
  @include hoverEffect;

  &:nth-child(6n+1) {
    background-color: #f3f3e4;
  }

  &:nth-child(6n+2) {
    background-color: #e1e3d4;
  }

  &:nth-child(6n+3) {
    background-color: #d0d4c6;
  }

  &:nth-child(6n+4) {
    background-color: #dadedc;
  }

  &:nth-child(6n+5) {
    background-color: #eceeed;
  }

  &:nth-child(6n+6) {
    background-color: #f5f6f6;
  }

  &__btn {
    display: block;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: $letterSpacing;
    line-height: 25px;
    padding: 20px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 90px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__main {
    @include media-breakpoint-up(lg) {
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 380px;
        width: 400px;

        @include media-breakpoint-up(xl) {
          height: 510px;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    min-height: 235px;
    padding: 10px 30px 20px;
    position: relative;

    @include media-breakpoint-up(lg) {
      transform: rotate(90deg);
      transform-origin: 200px;
      height: 400px;
      width: 380px;
      padding-top: 50px;
      padding-left: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 510px;
      padding-right: 70px;
    }
  }

  &__text {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 10px;

    @include media-breakpoint-up(xl) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__label {
    @include readMore;
  }

  &:hover &__label {
    border-color: #000;
  }
}
