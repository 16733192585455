.footer {
  background-color: $primary;
  color: #f3f3e4;
  padding: 30px;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    padding-bottom: 100px;

    &__container {
      max-width: 940px;
    }

    &__top {
      margin-bottom: 100px;
      text-align: center;
    }

    &__bottom {
      display: flex;
      align-items: flex-end;
      // justify-content: space-between;
      margin-top: 100px;
    }
  }

  a {
    color: #f3f3e4;
  }
}

.footerSocial {
  margin-bottom: 50px;
  text-align: center;

  &__link {
    margin: 0 15px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    width: 33.34%;
  }
}

.footerSiteActions {
  display: flex;
  flex-wrap: wrap;
  font-family: $font-family-sans-condensed;
  font-size: 12px;
  justify-content: center;
  letter-spacing: $letterSpacing;
  line-height: 15px;
  margin-bottom: 35px;
  text-transform: uppercase;
  @include list-unstyled;

  li {
    margin: 0 10px 15px;
  }

  a {
    @include contentLink;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    width: 33.34%;

    li {
      margin-bottom: 0;
    }
  }
}

.footerLogos {
  text-align: center;

  @include media-breakpoint-up(lg) {
    order: -1;
    text-align: left;
    width: 33.34%;
  }

  &__link {
    margin: 0 15px;
  }
}

.footerMenu {
  display: flex;
  font-family: $font-family-sans-condensed;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.09em;
  line-height: 20px;
  margin-bottom: 25px;
  @include list-unstyled;

  > li {
    margin: 0 17px;
    width: 148px;
  }

  &__link {
    display: block;
    margin-bottom: 30px;
  }
}

.footerSubMenu {
  @include list-unstyled;
  text-transform: uppercase;

  li {
    margin-bottom: 10px;
  }

  a {
    @include contentLink;
  }
}
