$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Sans Cond ExtLt'),
    local('IBMPlexSansCond-ExtLt'),
    url('#{$font-prefix}/IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-ExtraLight.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-ExtraLight.woff') format('woff');
}

@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';