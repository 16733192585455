.sectionNews {
  margin-bottom: 50px;
  padding: 0 20px;

  @include media-breakpoint-up(xl) {
    max-width: 1098px;
    margin-bottom: 100px;
  }
}

.newsSlider {
  background-color: #e1e3d4;
  color: #1c2523;
  height: auto;
  min-height: 550px;
  padding: 20px 30px;
  @include hoverEffect;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33.33%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: $letterSpacing;
    margin-bottom: 1.25rem;
    text-align: center;
    @include sansFontFamily;
  }

  &__figure {
    margin-bottom: 1.25rem;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 1.25rem;

    &--big {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__more {
    @include readMore;
  }

  &:hover &__more {
    border-color: #000;
  }
}

.newsSliderFigure {
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s ease-out;

    .newsSlider:hover & {
      transform: scale(1.1);
    }
  }
}
