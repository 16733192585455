// Font
$font-family-sans: 'IBM Plex Sans', sans-serif;
$font-family-sans-condensed: 'IBM Plex Sans Condensed', sans-serif;

// Body
$body-bg:     #f5f6f6;
$body-color:  #000;

// Links
$link-color:                              $body-color;
$link-hover-color:                        $body-color;
$link-hover-decoration:                   none;

// Paragraphs
$paragraph-margin-bottom: 50px;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);

// Typography
$font-family-base:      'IBM Plex Serif', serif;
$headings-font-weight:  400;

// Buttons
$btn-padding-y:     15px;
$btn-padding-x:     35px;
$btn-font-family:   $font-family-sans-condensed;
$btn-font-size:     14px;
$btn-line-height:   20px;
$btn-border-radius: 28px;
$btn-border-width:  0;

// Form Controls
$label-margin-bottom:     0.625rem;
$input-padding-y:         0.625rem;
$input-padding-x:         1.25rem;
$input-font-family:       $font-family-sans;
$input-font-size:         17px;
$input-line-height:       1.77;
$input-focus-box-shadow:  0 0 0 3px #91cdcd;
$input-bg:                #f5f6f6;
$input-btn-border-width:  0;
$input-border-radius:     0;

// Theme
// Colors
$primary:   #344643;
$secondary: #e1e3d4;
$hover: #cacbbd;
$active: #91cdcd;
$disabled: #f5f6f6;

// Letter spacing
$letterSpacing: 0.07em;
