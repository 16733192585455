.projectsListing {
  border-top: 3px solid #e1e3d4;
}

.projectsItem {
  border-bottom: 3px solid #e1e3d4;
  padding: 20px 0;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  &__title {
    font-family: $font-family-sans-condensed;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: $letterSpacing;
    line-height: 1.67;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      padding-right: 10px;
      width: 180px;
    }
  }

  &__link {
    @include contentLink;
  }

  &__description {
    font-weight: 500;
  }
}
