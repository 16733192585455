$font-prefix: '../fonts' !default;

// @import 'mono/index';
@import 'sans/index';
@import 'sans-condensed/index';
// @import 'sans-hebrew/index';
// @import 'sans-devanagari/index';
// @import 'sans-thai/index';
// @import 'sans-thai-looped/index';
@import 'serif/index';
// @import 'sans-arabic/index';
