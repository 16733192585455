.portletTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        font-size: 22px;
        line-height: 1.6;
        margin-bottom: 30px;
    }
}

.portletShowAll {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    @include sansFontFamily;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 32px;
    }

    @include media-breakpoint-up(lg) {
        display: inline-block;
        text-align: left;
        width: 140px;

        .js & {
            padding-left: 40px;
        }
    }

    &__link {
        @include link;
        letter-spacing: $letterSpacing;
    }
}

.portletOverviewCollection {

    @include media-breakpoint-up(lg) {
        padding: 0 35px !important;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .portletItem {
            flex: 33.33%;
            padding: 15px;

            @include media-breakpoint-down(lg) {
                flex: 50%;
            }

            &__background {
                display: block;
                background-size: cover;
                background-position: center;
                height: 100%;
                width: 100%;

                &:before {
                  content: "";
                  display: block;
                  padding-top: 100%;
                  float: left;
                }

                &.hasBackgroundImage {
                    &:hover {
                        .portletItem__wrapper {
                            background: transparent !important;
                        }
                    }
                }

                &:not(.hasBackgroundImage) {
                    &:hover {
                        filter: brightness(70%);
                    }
                }
            }

            &__wrapper {
                padding: 30px;
                height: 100%;
                width: 100%;
            }

            &__inner {
                position: relative;
                height: 100%;
                width: 100%;
                padding: 15px;
                transition: padding 0.3s;

                .readMore {
                    text-decoration: underline;
                    text-transform: uppercase;
                    position: absolute;
                    bottom: 0;
                    transition: bottom 0.3s;
                }
            }

            &__title {
                font-weight: 500;
                line-height: 1.5em;
                padding-bottom: 20px;

                a {
                }
            }

            &__description {

            }
        }
    }
}
