// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=IBM+Plex+Sans+Condensed:wght@500&family=IBM+Plex+Serif:wght@400;500;600&display=swap');
@import "../fonts/scss/ibm-plex";
@import "./variables";

@import "bootstrap/scss/bootstrap";
@import "./mixins";
@import "./plone";
@import "./forms";
@import "./buttons";
@import "./accordion";
@import "./layout";
@import "./header";
@import "./main-nav";
@import "./search/main";
@import "./footer";
@import "./aside-nav";
@import "./content";
@import "./portlets";
@import "./swiper";
@import "./stage";
@import "./news";
@import "./publications";
@import "./project/main";
@import "./facts";
@import "./questions";
@import "./related";
@import "./summary-view";
@import "./publications-listing";
@import "./person";
@import "./pik-in-media";
@import "./event";
@import "./pik-news";
@import "./accessibility";
@import "./mobile-nav";
@import "./quick_links";
