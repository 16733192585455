.app {
  background-color: #fff;
  max-width: 1800px;
  margin: auto;
}

.main {
  min-height: 608px;
  padding-bottom: 1px;
}

.aboveContent {
  // background-color: #82a096;
  font-family: $font-family-sans;
  min-height: 250px;
  margin-right: 20px;
  margin-bottom: 35px;
  padding: 20px;

  &[data-portrait] {
    position: relative;
    margin-bottom: 190px;
    padding-bottom: 125px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: calc((100% - 875px) / 2);
    margin-bottom: 70px;
    padding-left: calc((100% - 875px) / 2 * 0.6);

    &[data-portrait] {
      padding-bottom: 20px;
      margin-bottom: 70px;
    }
  }

  @include media-breakpoint-up(xxl) {
    margin-right: 25.7%;
    padding-left: 15%;
  }

  &__body {
    @include media-breakpoint-up(lg) {
      max-width: 512px;
    }
  }
}

.breadcrumbs {
  font-family: $font-family-sans-condensed;
  font-size: 12px;
  letter-spacing: $letterSpacing;
  line-height: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;

  &--topic {
    font-size: 14px;
    text-transform: none;
  }

  a {
    color: #000;
  }
}

.aboveContent,
.sectionStage {
  .breadcrumbs {
    font-weight: 500;

    span:nth-last-child(2) .breadcrumbSeparator {
      display: none;
    }
  }

  #breadcrumbs-current {
    display: none;
  }
}

.firstHeading {
  font-size: 25px;
  letter-spacing: 0.02em;
  line-height: 1.4;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 1.34;
  }
}

.description {
  font-size: 20px;
  line-height: 1.5;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

.content {
  margin-bottom: 70px;
  @include contentDimensions;
}

.contentTags {
  @include contentDimensions;
  margin-bottom: 70px;

  .link-category {
    background-color: #91cdcd;
    border-radius: $btn-border-radius;
    display: inline-block;
    font-family: $btn-font-family;
    font-size: .875rem;
    line-height: 1.43;
    margin: 0 5px 10px;
    padding: 5px 19px;
    text-transform: uppercase;

    &:hover {
      background-color: darken(#91cdcd, 15%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem darken(#91cdcd, 15%);
    }
  }
}

.isJsEnable {
  html.js & {
    display: block;

    &--inlineBlock {
      display: inline-block;
    }
  }
}

// Miscellaneous
.template-blog-view h2,
.template-blog-view p.linklist,
.template-blog-view div.documentByLine,
.template-blog-view div.tileFooter {
  display: none;
}
