$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Medium'),
    local('IBMPlexSans-Medium'),
    url('#{$font-prefix}/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Medium.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Medium.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';
@import 'greek';