.hiddenStructure {
  @include sr-only;
}

.visualClear {
  clear: both;
}

.visualNoMarker {
  @include list-unstyled;
}

.portlet:not(.managedPortlet),
.portletStaticText {
  margin-bottom: 70px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  @include contentDimensions;
}

.portletCollection {
  img {
    max-width: 100%;
    height: auto;
  }
}

// TinyMCE Styles
.mceContentBody a {
  @include contentCore;
  color: $body-color !important;
  border-color: currentColor !important;
}

/* Pagination */
nav.pagination {
  ul {
    margin: 1em 0;
    padding-inline-start: 0;

    li {
      display: inline;
      padding: 0.25em 0.5em !important;

      &.active {
        font-weight: 600;
      }

      &.previous {
        &::before {
          content: "«";
        }
      }

      &.next {
        &::after {
          content: "»";
        }
      }

      a {
        border: 0 !important;
      }
    }
  }
}

// Action menu
.actionMenu {
  margin: 0;
  position: relative;
  white-space: nowrap;
}

.actionMenuContent {
  background-color: #fff;
  right:0;
  margin:0;
  min-width:100%;
  padding:0 3px 3px;
  padding:10px;
  position:absolute;
  width:auto;
  z-index:5;

  .actionMenu.deactivated & {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.actionMenuHeader {
  font-weight: normal;
}
