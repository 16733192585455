.sectionPublications {
  max-width: 303px;
  margin: 0 auto 50px;

  @include media-breakpoint-up(md) {
    max-width: 726px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
    max-width: 1089px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 22px;
      line-height: 1.6;
      margin-bottom: 30px;
    }
  }
}

.publicationsSliderContainer {
  background-color: #e1e3d4;
  margin: 0 10px 10px 20px;
  width: 273px;
  height: 400px;

  @include media-breakpoint-up(md) {
    margin: 0 40px 10px 50px;
  }
}

.publicationsSlider {
  background-color: #8a9079;
  color: #000;
  display: block;
  height: 100%;
  padding: 30px;
  transform: translate(-10px, 10px);
  transition: transform .3s;
  @include hoverEffect;

  &:hover,
  &:focus {
    transform: translate(-20px, 20px);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__name {
    font-family: $font-family-sans;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &__author {
    font-family: $font-family-sans;
    font-size: 14px;
    line-height: 25px;
    margin-top: auto;
  }
}
