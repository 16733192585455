@media (min-width: 767px) {
    .plone-toolbar-left #edit-zone {
        #quick-links-container {
            position: absolute;
            bottom: 50px;
            border-top: 1px solid #ddd;
            background-color: black;
            box-shadow: -6px 2px 6px 2px #ddd;

            ul {
                position: fixed;
                top: -50px;
                height: 100%;
            }
        }
    }
}
