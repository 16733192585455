$font-prefix: '..' !default;
@import 'bold/index';
@import 'bold/italic/index';
@import 'extralight/index';
@import 'extralight/italic/index';
@import 'italic/index';
@import 'light/index';
@import 'light/italic/index';
@import 'medium/index';
@import 'medium/italic/index';
@import 'regular/index';
@import 'semibold/index';
@import 'semibold/italic/index';
@import 'text/index';
@import 'text/italic/index';
@import 'thin/index';
@import 'thin/italic/index';