.sectionFacts {
  margin-bottom: 50px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
  }
}

.factsSlider {
  background-color: #fff;
  height: auto;
  padding: 50px 0 50px 50px;
  position: relative;
  
  @include media-breakpoint-up(lg) {
    padding: 100px 0 100px 20.45%;
  }

  &__content {
    background-color: #91cdcdcc;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    position: relative;

    @include media-breakpoint-up(lg) {
      padding: 50px 32.65% 50px 12.72%;
    }
  }

  &__title {
    font-family: $font-family-sans;
    font-size: 40px;
    line-height: 1.25;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 50px;
      line-height: 1.2;
    }
  }

  &__text {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    a {
      @include borderedLink;
    }

    p {
      margin-bottom: 30px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    font-family: $font-family-sans-condensed;
    font-size: 14px;
    letter-spacing: 0.06667em;
    line-height: 20px;
    margin-top: auto;
    text-transform: uppercase;
  }

  &__more,
  &__nextLabel {
    @include contentLink;
  }

  &__more {
    margin-right: 30px;
  }

  &__next {
    white-space: nowrap;

    &.disabled {
      display: none;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.factsSliderFigure {
  margin: 0;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 20px;

  @include media-breakpoint-up(lg) {
    right: 11.11%;
    left: 11.11%;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    background-color: #91cdcdcc;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
