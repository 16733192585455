$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Serif ExtraLight'),
    local('IBMPlexSerif-ExtraLight'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-ExtraLight.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-ExtraLight.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';