.mobileNavToggle {
  display: block;
  width: 70px;
  text-align: center;
  margin-left: -20px;

  &__text {
    font-size: 11px;
    margin-top: -5px;
    text-transform: uppercase;
    display: none;

    .mm-wrapper_opened & {
      display: block;
    }
  }
}

#hamburger {
  position: absolute;
  top: -15px;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  .mburger {
    --mb-button-size: 40px;
    --mb-bar-spacing: 6px;
    --mb-bar-height: 3px;
    color: #344643;
  }
}

.mobileNav {
  --mm-color-background: #344643;
  --mm-color-text: #f3f3e4;
  --mm-color-text-dimmed: #f3f3e4;
  --mm-color-button: #f3f3e4;
  --mm-color-border: #f3f3e4;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &.mm-menu_opened {
    position: absolute;
  }

  #nav-bar-breadcrumbs {
    text-align: left;
    justify-content: left;
    padding: 0 20px;
    overflow: hidden;
    display: block;
    white-space: nowrap;

    .nav-bar-breadcrumbs-inner {
      width: 100%;
      overflow: hidden;
      margin-top: 10px;

      > span {
        display: inline-block;
      }

      .breadcrumbSeparator {
        padding: 0 3px;
      }
    }
  }
}

.mobileMenu {
  @include list-unstyled;
  margin: 0;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 25px;
  }

  @include media-breakpoint-up(lg) {
    letter-spacing: 0.07143em;
    line-height: 26px;

    li {
      padding: 7px 0;
    }

    a {
      border-bottom: 2px solid transparent;
      padding: 2px 0;
      vertical-align: top;

      &:hover,
      &:focus {
        border-color: $secondary;
        text-decoration: none;
      }
    }
  }
}
