$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Serif Italic'),
    local('IBMPlexSerif-Italic'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Italic.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Italic.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';